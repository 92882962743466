import React, { useEffect } from "react"

import { useRouteError } from "react-router-dom"

import emptyBoxSrc from "~/images/empty_project_cart.png"
import { Bee } from "~/src/components/BeeKit"
import { AxiosClientError } from "~/src/lib/appClients"

/**
 * ErrorPage component is used to display a user-friendly error message when something goes wrong.
 * This component is designed to be used with React Router's error handling.
 *
 * @component
 * @example
 * ```tsx
 * <ErrorPage />
 * ```
 *
 * @remarks
 * This component utilizes the `useRouteError` hook from `react-router-dom` to capture and display route errors.
 * It also includes a button link to email support for further assistance.
 *
 * @param props - The props for the ErrorPage component.
 *
 * @returns A React component that renders an error message and a support link.
 */
export function ErrorPage() {
  const error = useRouteError()

  useEffect(() => {
    if (import.meta.env.PROD) {
      // @ts-ignore
      window?.Rollbar?.error(error)
    } else {
      console.error(error)
    }
  }, [error])

  if (error instanceof AxiosClientError && error.status == 401) {
    return (
      <div className="m-auto flex h-[calc(100vh-4.5rem)] flex-col items-center justify-center">
        <Bee.Spinner className="h-12 w-12" />
      </div>
    )
  }

  return (
    <div className="m-auto flex h-[calc(100vh-4.5rem)] max-w-[417px] flex-col items-center justify-center gap-2 px-6 md:px-0">
      <img src={emptyBoxSrc} alt="Unexpected Error" className="h-24 w-24" />
      <hgroup className="flex flex-col gap-1 text-center">
        <h1 className="text-xl font-medium text-gray-900 md:text-2xl">Oops, something went wrong.</h1>
        <div className="text-sm text-gray-500 md:text-base">
          <span>
            We’re sorry, an unexpected error has occurred. Our team has been notified of the issue. If you need
            immediate help, please{" "}
          </span>
          <a className="underline" href="mailto:support@brilliantmade.com" target="_blank">
            contact support.
          </a>
        </div>
      </hgroup>
    </div>
  )
}
